import Sidebar from 'components/container-components/navbar/Sidebar';
import { useUser } from 'context/user';
import { NextPage } from 'next';

type PageConfig = {
  isNarrowWidth?: boolean;
  hasSidebar?: boolean;
};

export const PageSkeletonLoader = () => (
  <div className="my-6 flex animate-pulse flex-col gap-y-6">
    <div className="flex w-full items-center space-x-4">
      <div className="h-6 w-6 rounded-md bg-neutral-200"></div>
      <div className="h-4 w-1/2 rounded-md bg-neutral-200"></div>
    </div>
  </div>
);

const withLayout =
  (PageComponent: NextPage, pageConfig: PageConfig = {}) =>
  (props: any) => {
    const { isNarrowWidth, hasSidebar } = pageConfig || {};
    const { isLoading } = useUser() || {};

    return (
      <>
        {hasSidebar && <Sidebar isLoading={isLoading} />}
        <div className={`grid flex-1 grid-cols-[1fr] grid-rows-[1fr]${hasSidebar ? ' ml-[244px]' : ''}`}>
          <div className={`relative mx-auto w-full px-6 ${isNarrowWidth ? 'max-w-screen-lg' : 'max-w-screen-2xl'}`}>
            {/* {isLoading ? <PageSkeletonLoader /> : <PageComponent {...props} />} */}
            <PageComponent {...props} />
          </div>
        </div>
      </>
    );
  };

export default withLayout;
