import React, { useMemo } from 'react';
import Link from 'next/link';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { SubNavItem as SubNavItemInterface } from './navigations';
import { useNavigation } from './NavigationContext';
import { useCompany } from 'context/company';
import useAdminInvoiceSWR from 'hooks/useAdminInvoiceSWR';
import { useUser } from 'context/user';
import { usePermissions } from 'hooks/usePermissions';

interface SubNavItemProps {
  item: SubNavItemInterface;
  index: number;
}

export const SubNavItem: React.FC<SubNavItemProps> = ({ item, index }) => {
  const { permissionsVersion, roleType } = useUser();
  const { isOrgAdmin } = usePermissions();
  const { company } = useCompany();
  const companyID = company?.companyID;
  const { openInvoicesCount, approvedInvoicesCount } = useAdminInvoiceSWR({
    companyID,
    companyInvoiceAvailabilityDaysCount: company?.settings?.paymentAllowedDaysBeforeDueDate,
  });
  const { activeSubNav, activeSubSideNav } = useNavigation();
  const isSubNavActive = activeSubSideNav !== -1 ? false : index === activeSubNav;

  const total = useMemo(() => openInvoicesCount + approvedInvoicesCount, [approvedInvoicesCount, openInvoicesCount]);

  return (
    <li key={item.name} className="w-full">
      {item.category && <p className="mb-1.5 text-sm text-gray-400">{item.category}</p>}
      {!item.category && (
        <Link href={item.href}>
          <span
            className={`relative flex items-center justify-between rounded px-3 py-2 font-medium text-neutral-800 hover:bg-neutral-100 ${
              isSubNavActive ? `w-full bg-neutral-100` : ''
            }`}
          >
            <span className="flex items-center">
              <div className="relative h-6 w-6">
                <span className="inline-block w-6">{item.icon}</span>
                {item.name.toUpperCase() === 'CONTRACTORS' && total ? (
                  <div className="absolute -top-1.5 left-3 z-10 flex h-4 w-4 items-center justify-center rounded-full bg-red-600 text-[7px] text-white">
                    {total}
                  </div>
                ) : null}
              </div>
              <span className="ml-3 text-sm">{item.name}</span>
            </span>
            {item.subNavs && !item.isCustomElement && !item.subNavs.every((i) => i.isShownInBar === false) && (
              <ChevronDownIcon className="h-4 w-4 text-neutral-500" aria-hidden="true" />
            )}
          </span>
        </Link>
      )}
      <div className="sub-item relative">
        {item.subNavs
          ?.filter((subNav) => (subNav.isShownInBar?.toString() === 'false' ? false : true))
          ?.map((subNavItem, subNavindex) => {
            if (subNavItem.onlyShownTo === 'permV2' && permissionsVersion !== 2) {
              return null;
            }
            if (subNavItem.onlyShownTo === 'orgAdmin' && !isOrgAdmin && roleType === 'manager') {
              return null;
            }
            return (
              <Link href={subNavItem.href} key={subNavItem.href} className={`hover:bg-neutral-100`}>
                <div
                  className={`top-0.5 ml-10 rounded py-2 hover:bg-neutral-100 ${
                    activeSubSideNav === subNavindex && index === activeSubNav && 'rounded bg-neutral-100'
                  }`}
                >
                  {item.category ? (
                    <div className="with-category -ml-8 flex items-center gap-3">
                      <span className="h-6 w-6">{subNavItem.icon}</span>
                      <span className="text-sm">{subNavItem.name}</span>
                    </div>
                  ) : (
                    <span className="no-category relative pl-2 text-sm font-medium text-neutral-800">
                      {subNavItem.name}
                    </span>
                  )}
                </div>
              </Link>
            );
          })}
      </div>
    </li>
  );
};
