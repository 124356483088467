import { Menu, Transition } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import { useRouter } from 'next/router';
import { PlusIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { TeamAndDetails } from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';

import ArrowDown from 'icons/ArrowDown';
import Button from 'components/generic-components/button';
import Settings from 'icons/Settings';
import { AdminRoutes } from 'routes';
import { getInitialsFromName } from 'utils/string';
import { usePermissions } from 'hooks/usePermissions';
import { useUser } from 'context/user';

const TeamMenu = ({
  selectedTeamId,
  setSelectedTeamId,
  teams,
}: {
  setSelectedTeamId: (teamId: string) => void;
  selectedTeamId: string;
  teams: Record<string, TeamAndDetails>;
}) => {
  const router = useRouter();
  const { showAllTeamsContracts, setShowAllTeamsContracts } = useUser();
  const { canManageTeams } = usePermissions();
  const selectedTeam = teams[selectedTeamId];
  const selectedTeamName = selectedTeam?.name;
  const selectedTeamInitials = getInitialsFromName(selectedTeamName);
  const isAllSelected = showAllTeamsContracts;

  // sort selected team to the top
  const sortedTeams = useMemo(() => {
    const selectedTeam = teams[selectedTeamId];
    const teamsCopy: Record<string, TeamAndDetails> = JSON.parse(JSON.stringify(teams));
    delete teamsCopy[selectedTeamId];
    return {
      [selectedTeamId]: selectedTeam,
      ...teamsCopy,
    };
  }, [teams, selectedTeamId]);

  const goToManageTeam = (teamId: string) => {
    router.push({
      pathname: AdminRoutes.ManageTeam,
      query: { teamId },
    });
  };

  const handleTeamSelection = (teamId: string) => {
    if (teamId === 'all') {
      setShowAllTeamsContracts(true);
    } else {
      setSelectedTeamId(teamId);
      setShowAllTeamsContracts(false);
    }
  };

  return (
    <Menu>
      <div className="relative mb-3 flex w-full">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-[100] mt-10 max-h-[360px] w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="mb-1 flex items-center justify-between px-4 py-2">
              <p className="text-xs text-neutral-600">TEAMS</p>
            </div>
            {Object.keys(sortedTeams)?.length > 0 &&
              Object.keys(sortedTeams).map((teamId) => {
                const isSelected = !showAllTeamsContracts && teamId === selectedTeamId;
                const team = sortedTeams[teamId];
                const teamName = team?.name;
                const teamInitials = getInitialsFromName(teamName);
                return (
                  <Menu.Item
                    key={teamId}
                    as="a"
                    className={`flex items-center justify-between px-4 py-3 text-sm hover:cursor-pointer hover:bg-gray-100 ${
                      isSelected ? 'bg-gray-100 font-medium text-therablue' : 'font-normal text-neutral-700'
                    }`}
                    onClick={() => handleTeamSelection(teamId)}
                  >
                    <div className="flex items-center">
                      <span className="mr-2 flex h-6 w-6 items-center justify-center rounded-md bg-therablue-dark text-[10px] font-medium text-white">
                        {teamInitials}
                      </span>
                      <span>{teamName}</span>
                    </div>
                  </Menu.Item>
                );
              })}
            <Menu.Item
              key={'all'}
              as="a"
              className={`flex items-center justify-between border-t border-gray-200 px-4 py-3 text-sm hover:cursor-pointer hover:bg-gray-100 ${
                isAllSelected ? 'bg-gray-100 font-medium text-therablue' : 'font-normal text-neutral-700'
              }`}
              onClick={() => handleTeamSelection('all')}
            >
              <div>
                <span>All Teams</span>
              </div>
            </Menu.Item>
            {canManageTeams && (
              <>
                <Button
                  variant="plain"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToManageTeam(selectedTeamId);
                  }}
                  className="w-full"
                >
                  <div className="flex w-full items-center border-t border-gray-200 px-4 py-3 hover:bg-gray-100">
                    <Settings className="mr-2 h-4 w-4 fill-neutral-400 group-hover:fill-neutral-900 " />
                    <span className="text-sm">Team Settings</span>
                  </div>
                </Button>

                <Link href={AdminRoutes.AddTeam} className="flex w-full items-center">
                  <div className="flex w-full items-center border-t border-gray-200 px-4 py-3 hover:bg-gray-100">
                    <PlusIcon className="mr-2 h-5 w-4 fill-therablue-dark" />
                    <span className="flex-1 text-sm font-normal text-gray-800">Add Team</span>
                  </div>
                </Link>
              </>
            )}
          </Menu.Items>
        </Transition>
        <Menu.Button as={Fragment}>
          <a className="flex flex-1 cursor-pointer items-center rounded-md border border-neutral-200 px-1.5 py-1.5">
            <div className="flex h-[24px] max-w-[200px] flex-1 items-center truncate text-ellipsis text-left text-sm font-medium text-neutral-500">
              {showAllTeamsContracts ? (
                ''
              ) : (
                <span className="mr-2 flex h-6 w-6 items-center justify-center rounded-md bg-therablue-dark text-[10px] font-medium text-white">
                  {selectedTeamInitials}
                </span>
              )}
              <span>{showAllTeamsContracts ? 'All Teams' : selectedTeamName}</span>
            </div>
            <ArrowDown className="ml-1" />
          </a>
        </Menu.Button>
      </div>
    </Menu>
  );
};

export default TeamMenu;
