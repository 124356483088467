import { useMemo } from 'react';
import { EmploymentType } from '@thera-hr/api-sdk/api/resources/model/resources/thera';

import { useNavigation } from './NavigationContext';
import { useUser } from 'context/user';
import { useCompany } from 'context/company';
import { SubNavItem } from './SubNavItem';
import { usePermissions } from 'hooks/usePermissions';
import TeamMenu from 'components/app-components/contractors/TeamMenu';
import { useTeamsSWR } from 'hooks/useTeamsSWR';
import { getAvailableTeamsToSwitch } from 'utils/affiliations';

export default function Sidebar({ isLoading = false }: { isLoading?: boolean }) {
  const { navigation, activeMainNav, activeSubNav: _activeSubNav } = useNavigation();
  const { user, currentContract, permissionsVersion, affiliationsByCompany, currentTeamId, setCurrentTeamId } =
    useUser() || {};
  const { company } = useCompany() || {};
  const { isOrgAdmin } = usePermissions();
  const { teams } = useTeamsSWR();

  const isPermissionsV2 = company?.flags?.permissionsVersion === 2;
  const companyTeams = affiliationsByCompany?.[company?.companyID]?.teams;
  const availableTeamsToSwitch = useMemo(() => getAvailableTeamsToSwitch(teams, companyTeams), [companyTeams, teams]);

  const isEmployee = useMemo(() => currentContract?.w2Payroll?.zealEmployeeId, [currentContract]);
  const isContractor = useMemo(() => user?.userEmploymentType === EmploymentType.Contractor, [user]);
  const isW2Admin = useMemo(() => !user?.employeeInformation && !!company?.w2Payroll?.zealId, [user, company]);
  const { hasEOREmployees } = company || {};
  const isUSCompany = company?.address?.country === 'US';

  const userFlags = {
    paymentsVersion: user?.employeeInformation?.paymentsVersion,
  };

  if (isLoading) {
    return <SidebarSkeleton />;
  }

  const canShowTeamMenu = isPermissionsV2 && teams && Object.keys(teams)?.length > 0 && currentTeamId;

  return (
    <div
      id="subNav-wrapper"
      className="fixed min-h-[100vh] min-w-[244px] max-w-[244px] border border-r border-t-0 border-neutral-200 bg-white px-3 pt-4"
    >
      <ul className="flex min-w-[220px] flex-col items-center gap-1">
        {navigation[activeMainNav]?.subNavs
          ?.filter((subNav) => subNav.isShownInBar && subNav.isDisabled !== true)
          ?.map((item, index) => {
            if (item.isCustomElement && item.name === 'teamMenu') {
              if (canShowTeamMenu) {
                return (
                  <TeamMenu
                    selectedTeamId={currentTeamId}
                    setSelectedTeamId={setCurrentTeamId}
                    teams={availableTeamsToSwitch}
                    key="teamMenu"
                  />
                );
              } else {
                return null;
              }
            }
            if (item.onlyShownTo === 'permV2' && permissionsVersion !== 2) {
              return null;
            }
            if (item.onlyShownTo === 'employee' && !isEmployee) {
              return null;
            }
            if (item.onlyShownTo === 'contractor' && !isContractor) {
              return null;
            }
            if (item.onlyShownTo === 'w2Admin' && !isW2Admin) {
              return null;
            }
            if (item.onlyShownTo === 'orgAdmin' && !isOrgAdmin) {
              return null;
            }
            if (item.onlyShownTo === 'hasEOREmployees' && !hasEOREmployees) {
              return null;
            }
            if (item.onlyShownTo === 'isUSCompany' && !isUSCompany) {
              return null;
            }
            if (item.userFlags) {
              const { paymentsVersion } = item.userFlags;
              if (paymentsVersion && paymentsVersion !== userFlags.paymentsVersion) {
                return null;
              }
            }
            return <SubNavItem key={item.name} item={item} index={index} />;
          })}
      </ul>
    </div>
  );
}

export const SidebarSkeleton = () => (
  <div
    id="subNav-wrapper"
    className="fixed min-h-[100vh] min-w-[244px] max-w-[244px] border border-r border-t-0 border-neutral-200 bg-white px-3 pt-4"
  >
    <div className="flex animate-pulse flex-col gap-y-6">
      <div className="flex w-full items-center space-x-4">
        <div className="h-6 w-6 rounded-md bg-neutral-200"></div>
        <div className="h-4 w-1/2 rounded-md bg-neutral-200"></div>
      </div>
      <div className="flex w-full items-center space-x-4">
        <div className="h-6 w-6 rounded-md bg-neutral-200"></div>
        <div className="h-4 w-1/2 rounded-md bg-neutral-200"></div>
      </div>
      <div className="flex w-full items-center space-x-4">
        <div className="h-6 w-6 rounded-md bg-neutral-200"></div>
        <div className="h-4 w-1/2 rounded-md bg-neutral-200"></div>
      </div>
      <div className="flex w-full items-center space-x-4">
        <div className="h-6 w-6 rounded-md bg-neutral-200"></div>
        <div className="h-4 w-1/2 rounded-md bg-neutral-200"></div>
      </div>
    </div>
  </div>
);
